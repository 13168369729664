import * as Swiper from 'swiper/dist/js/swiper.js';

document.addEventListener('DOMContentLoaded', function () {
	const slider = document.querySelector('#ios-app-slider');
	if( !!slider )
	{

		const steps = document.querySelectorAll('.ios-app__step');
		const stepsContent = document.querySelector('.ios-app__content');
		const iosSlider = new Swiper(slider, {
			navigation: {
				nextEl: slider.querySelector('.ios-app__gallery-arr-next'),
				prevEl: slider.querySelector('.ios-app__gallery-arr-prev'),
			},
			// Pagination
			pagination: {
				el: slider.querySelector(".ios-app__gallery-pagination"),
				clickable: true,
				type: "bullets",
			},
		});
		iosSlider.on('slideChange', (swiper) => {
			let realIndex = iosSlider.realIndex
			let steps = document.querySelectorAll('.ios-app__step');

			if( realIndex === Array.from(steps).length )
				stepsContent.classList.add('_hidden');
			else
			{
				stepsContent.classList.remove('_hidden');


				if (window.innerWidth > 991) {
					[...steps].map(item => item.classList.remove('current'));
					steps[realIndex].classList.add('current');
				}
			}

			if( window.innerWidth <= 991 )
			{
				$(document).find('.ios-app__step:not(.current) .ios-app__step-mobile-body').slideUp();
				$(document).find('.ios-app__step.current .ios-app__step-mobile-body').slideDown();
			}


		});

		if( !!steps )
		{
			[...steps].map((item, index) => {
				item.addEventListener('click', () => {
					iosSlider.slideTo(index);
					if (window.innerWidth <= 991) {
						if (item.classList.contains('current')) {
							$(document).find('.ios-app__step .ios-app__step-mobile-body').slideUp();
							item.classList.remove('current');
						} else {
							[...steps].map(item => item.classList.remove('current'));
							$(document).find('.ios-app__step:not(.current) .ios-app__step-mobile-body').slideUp();
							item.classList.add('current');
							$(document).find('.ios-app__step.current .ios-app__step-mobile-body').slideDown();
						}
					}

				})
			});
		}
	}
})

