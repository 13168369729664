import * as Swiper from 'swiper/dist/js/swiper.js';

document.addEventListener('DOMContentLoaded', () => {
  [...document.querySelectorAll('.banner-analytics')].forEach(banner => {
    const slider = banner.querySelector('.courses-slider');

    if (!slider) {
      return;
    }

    const coursesSlider = new Swiper(slider, {
      init: false,
      loop: false,
      slidesPerView: 'auto',
      centeredSlides: true,
      speed: 500,
      slideToClickedSlide: true,
      navigation: {
        nextEl: '.courses-slider__next-button',
        prevEl: '.courses-slider__prev-button',
      }
    });

    if (slider.classList.contains('courses-slider_desktop')) {
      coursesSlider.init();
    } else if (window.innerWidth < 768) {
      coursesSlider.init();
    }
  });
});
