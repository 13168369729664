import * as Swiper from 'swiper/dist/js/swiper.js';

document.addEventListener('DOMContentLoaded', () => {
    [...document.querySelectorAll('.news-wrapper-mobile')].forEach(news_slider => {

        const slider = news_slider.querySelector('.news-wrapper-mobile__slider');
        const bullets = news_slider.querySelector('.news-wrapper-mobile__pagination');

        if (!slider) {
            return;
        }

        if (window.innerWidth < 991) {
            const newsSlider = new Swiper(slider, {
                init: true,
                slidesPerView: 1,
                speed: 500,
                spaceBetween: 10,
                autoHeight: false,
                loop: true,
                pagination: {
                    el: bullets,
                    type: 'bullets',
                    clickable: true,
                },
            });
        }

    });
});
