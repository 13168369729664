import * as clipboard from "clipboard-polyfill"

document.addEventListener('DOMContentLoaded', function () {
  const buttonsCopy = [...document.querySelectorAll('.button-copy')];

  const copyContentToBuffer = element => {
    const requisites = [...element.children].reduce(
        (sum, child) => `${sum}\n${child.innerText.replace(/[\n\r]/i, ' ')}`, ''
    );

    clipboard.writeText(requisites);
  };

  buttonsCopy.forEach(buttonCopy => buttonCopy.addEventListener('click', event => {
    event.preventDefault();

    const { copyText, copyTarget } = buttonCopy.dataset;
    const targetElement = document.querySelector(copyTarget);
    const buttonTextElement = buttonCopy.querySelector('.button-copy__text');
    const buttonText = buttonTextElement.innerHTML;

    copyContentToBuffer(targetElement);

    buttonTextElement.innerHTML = copyText;
    buttonCopy.classList.add('button-copy_active');

    setTimeout(() => {
      buttonCopy.classList.remove('button-copy_active');
      buttonTextElement.innerHTML = buttonText;
    }, 5000);
  }));
})