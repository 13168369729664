document.addEventListener('DOMContentLoaded', () => {
  [...document.querySelectorAll('.message-button')].forEach(message_button => {
    let message_button_do = message_button.querySelector('.message-button__do-open');

    window.pulsAnim = new TimelineMax({repeat: -1, yoyo: true, repeatDelay: 0.5});

    let pulsAnim = window.pulsAnim;

    const pulse1 = message_button.querySelector('.message-button__pulses_one');
    const pulse2 = message_button.querySelector('.message-button__pulses_two');

    pulsAnim.to(pulse1, 0.4, { opacity: 1 }, 0 ).to(pulse2, 0.3, { opacity: 1 }, 0.2);

    message_button_do.addEventListener('click', () => {
      let messengersAnim = new TimelineLite();
      //const telegram_link = message_button.querySelector('.message-button__messengers-icon-telegram');
      //const whatsapp_link = message_button.querySelector('.message-button__messengers-icon-whatsapp');
      const messenger_links = message_button.querySelectorAll('.message-button__messengers-icon');
      const pulses = message_button.querySelector('.message-button__pulses');

      if (message_button.classList.contains('active')) {

        messengersAnim.to(pulses,0, {display: 'none', opacity: 0 })
            .to(messenger_links,0.5, {display: 'none', opacity: 0 })
            .to(pulses,0, {display: 'block', opacity: 1 });

        setTimeout(() => {
          pulsAnim.play(0);
        }, 450)

        message_button.classList.remove('active');

      } else {

        messengersAnim.to(pulses,0, {display: 'none', opacity: 0 })
            .to(messenger_links,0.5, {display: 'flex', opacity: 1 })

        pulsAnim.pause();

        message_button.classList.add('active');
      }
    })
  })
});
