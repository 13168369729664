document.addEventListener('DOMContentLoaded', () => {
  [...document.querySelectorAll('.android__wrapper')].forEach(wrap_dwnl_app => {
    wrap_dwnl_app.querySelector('.wrap__close').addEventListener('click', () => {
      wrap_dwnl_app.style.display = "none";
      //cookies for 30 days
      let date = new Date(Date.now() + 86400e3 * 30);
      document.cookie =  'button_dwn_mobile=close; expires=' + date.toUTCString() + '; path=/;';
    });
  })
});
