export default class WowReplace {
    static init() {
        const wows = [...document.getElementsByClassName('wowjs')];
        if (wows) {
            wows.forEach(wowjs => {
                wowjs.classList.add('wow');
                wowjs.classList.remove('wowjs');
            });
        }
    }
}