export default class Pixels {
  static init() {
    return new Pixels();
  }
  constructor() {
    this.conversionReg = document.querySelectorAll('[data-regbtn]');
    this.conversionOpenAccount = document.querySelectorAll('[data-openaccount]');
    this.bindPixel();
  }
  bindPixel() {
    if (this.conversionReg.length > 0) {
      this.conversionReg.forEach(convReg => convReg.addEventListener('click', (e) => {
        runRapPixel('bb0cf4a8-a3c5-4efb-be85-4d9802b3ec78');
      }));
    }
    if (this.conversionOpenAccount.length > 0) {
      this.conversionOpenAccount.forEach(openAccount => openAccount.addEventListener('click', (e) => {
        runRapPixel('2ac76361-97e5-423b-8567-00a7ee4931ec');
      }));
    }

  }
}
