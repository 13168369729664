import {TweenLite} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';

const plugins = [ScrollToPlugin]; // necessary for include plugin to bundle
const button = document.querySelector('.up-button');
const scrollY = () => window.scrollY || window.pageYOffset;
let buttonShown = false;

const isMobile = window.innerWidth <= 690;

if (button) {
  button.addEventListener('click', () => {
    if (scrollY() > 0) {
      TweenLite.to(window, 0.8, { scrollTo: 0, autoKill: false });
    }
  });

  const toggleButton = () => {
    if (isMobile) {
      buttonShown = true;
      TweenLite.set(button, {
        display: 'flex',
      });
    } else {
      if (scrollY() > 799 && !buttonShown) {
        buttonShown = true;
        TweenLite.fromTo(button, 0.3, {
          display: 'flex',
          opacity: 0,
        }, {
          opacity: 1,
        });
      } else if (buttonShown && scrollY() < 800) {
        buttonShown = false;
        TweenLite.to(button, 0.3, {
          opacity: 0,
          onComplete() {
            TweenLite.set(button, {
              clearProps: 'display',
            });
          },
        });
      }
    }
  };

  window.addEventListener('load', toggleButton);

  if (!isMobile) {
    window.addEventListener('scroll', toggleButton);
  }

}
