import AdaptiveSlider from '../adaptive-slider/adaptive-slider';

export default class MainFeaturesSlider {
  static init() {
    const elements = [...document.querySelectorAll('.feature-slider')];

    elements.forEach(element => {
      new MainFeaturesSlider(element);
    });
  }

  constructor(element) {
    var bullets = element.nextElementSibling;
    this.config = {
      element,
      config: {
        768: {
          init: false,
          speed: 200,
          freeModeMomentum: false,
          slidesPerView: 1,
          longSwipes: false,
          fadeEffect: {
            crossFade: true,
          },
          effect: 'fade',
          pagination: {
            el: bullets,
            type: 'bullets',
            bulletClass: 'feature-slider__bullet',
            bulletActiveClass: 'feature-slider__bullet_is_active',
            clickable: true,
          }
        },
      },
    };

    this.slider = new AdaptiveSlider(this.config);
    this.slider.init();
  }
}
