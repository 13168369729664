import 'babel-polyfill';
import WOW from 'wowjs';

import './polyfills/closest_matches';
import './polyfills/serialize';
import './polyfills/customEvent';
import './polyfills/after';
import './polyfills/remove';
import './polyfills/forEach';

import LazyLoad from 'vanilla-lazyload/dist/lazyload';
import WowReplace from 'components/wow-replace/wow-replace';
import MainFeaturesSlider from "components/feature-slider/feature-slider";
import Pixels from "components/pixels/pixels";

import 'components/header/header';
import 'components/ios-app/ios-app';
import 'components/up-button/up-button';
import 'components/messengers-button/messengers-button';
import 'components/download-app-topper-mobile/download-app-topper-mobile';
import 'components/example-slider/example-slider';
import "components/copy-button";
import "components/courses-slider/courses-slider";
import "components/news/news";
import "components/why-alfaforex/why-alfaforex";
import "components/advantages/advantages";
import "components/cards-features/cards-features";

const init = component =>
  ('init' in component)
    ? component.init()
    : (typeof component === 'function')
      ? component()
      : null;

document.addEventListener('DOMContentLoaded', () => {
  [
    WowReplace,
    MainFeaturesSlider,
    Pixels,
  ].map(init);

  new LazyLoad({
    elements_selector: '.lazy-image'
  });

  window.wow = new WOW.WOW({
    boxClass: 'wow',
    offset: 0,
    live: true,
  });
  window.wow.init();

  function addLink() {
    var body_element = document.getElementsByTagName('body')[0];
    var selection = window.getSelection();
    var copytext = selection + "\n" + 'Информация предоставлена: ' + document.location.href;
    var newdiv = document.createElement('div');
    newdiv.style.position = 'absolute';
    newdiv.style.left = '-99999px';
    body_element.appendChild(newdiv);
    newdiv.innerText = copytext;
    selection.selectAllChildren(newdiv);
    window.setTimeout(function () {
      body_element.removeChild(newdiv);
    }, 0);
  }
  document.oncopy = addLink;

  const changeBackground = () => {
    const backgroundMobileBlocks = [...document.querySelectorAll("[data-background-mobile]")];

    if (backgroundMobileBlocks) {
      backgroundMobileBlocks.forEach(block => {
        const isImage = block.tagName === 'IMG';

        const desktopBackground = block.getAttribute("data-background");
        const tabBackground = block.getAttribute("data-background-tab");
        const mobileBackground = block.getAttribute("data-background-mobile");

        if (mobileBackground && window.innerWidth < 768 && !isImage) {
          block.setAttribute("style", `background-image: url(${mobileBackground})`);
        } else if (mobileBackground && window.innerWidth < 768 && isImage) {
          block.setAttribute("src", mobileBackground);
        }

        if (tabBackground && window.innerWidth >= 768 && window.innerWidth < 992 && !isImage) {
          block.setAttribute("style", `background-image: url(${tabBackground})`);
        } else if (tabBackground && window.innerWidth >= 768 && window.innerWidth < 992 && isImage) {
          block.setAttribute("src", tabBackground);
        }

        if (desktopBackground && tabBackground && (window.innerWidth >= 992) && !isImage) {
          block.setAttribute("style", `background-image: url(${desktopBackground})`);
        } else if (desktopBackground && tabBackground && (window.innerWidth >= 992) && isImage) {
          block.setAttribute("src", desktopBackground);
        }

        if (desktopBackground && !tabBackground && (window.innerWidth >= 768) && !isImage) {
          block.setAttribute("style", `background-image: url(${desktopBackground})`);
        } else if (desktopBackground && !tabBackground && (window.innerWidth >= 768) && isImage) {
          block.setAttribute("src", desktopBackground);
        }
      });
    }
  };

  changeBackground();
  
  let infoMsgBtn = document.getElementById('close-info-message');
  if( !!infoMsgBtn )
  {
	  infoMsgBtn.addEventListener('click', (e) => {
		  e.preventDefault();
		  document.cookie = "info_message=1; path=/; max-age=259200";
		  const parent = infoMsgBtn.closest('.header-message');
		  parent.remove();
	  });
  }
  
  
});
