import * as Swiper from 'swiper/dist/js/swiper.js';

document.addEventListener('DOMContentLoaded', () => {
    [...document.querySelectorAll('.cards-features')].forEach(cards_features_slider => {

        const slider = cards_features_slider.querySelector('.cards-features__slider-swiper');
        const bullets = cards_features_slider.querySelector('.cards-features__pagination');

        if (!slider) {
            return;
        }

        const cardsFeaturesSlider = new Swiper(slider, {
            init: true,
            slidesPerView: 1,
            speed: 500,
            spaceBetween: 10,
            autoHeight: false,
            loop: false,
            pagination: {
                el: bullets,
                type: 'bullets',
                clickable: true,
            },
        });

    });
});
