const utils = {
  get scrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
  },
};

document.addEventListener('DOMContentLoaded', () => {
  utils.isMobile = window.innerWidth - document.documentElement.clientWidth === 0;
}, false);

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function validateNumber(input, defaultInput = 0) {
  if (input == '' || Number(input) === 0) return input
  return isNumber(input) ? input : defaultInput
}

export const isAndroid = () => /Android/i.test(navigator.userAgent);

export const isIos = () => /(iPad;)|(iPhone;)/i.test(navigator.userAgent);

export const getCoords = (elem) => {
  // (1)
  const box = elem.getBoundingClientRect();

  const body = document.body;
  const docEl = document.documentElement;

  // (2)
  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  // (3)
  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  // (4)
  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return {
    top: top,
    left: left
  };
};

export const scrollToElement = (elements, to, duration) => {
  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;

    if (t < 1) {
      return c / 2 * t * t + b;
    }

    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };

  [...elements].forEach((element) => {
    const start = element.scrollTop;
    const change = to - start;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = function () {
      currentTime += increment;
      const val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  });
};


/**
 * Reverse string
 * *
 * @example
 * qwerty -> ytrewq
 * *
 * @param {string} input
 * @return {string}
 */
export function reverseString(input) {
  return input.split('').reverse().join('')
}

/**
 * Bit number
 * *
 * @example
 * 100000000 -> 100 000 000
 * *
 * @param {number|string} input
 * @return {string}
 */
export function bitNumber(input, fractional = false) {
  const source = (typeof input === 'number' ? String(input) : input)
  let parseArr = source.split('.');
  let array = reverseString(parseArr[0]).match(/.{1,3}/g).reverse()

  return fractional
    ? array.map((value) => reverseString(value)).join(' ') + (parseArr[1] ? `.${parseArr[1]}` : '')
    : array.map((value) => reverseString(value)).join(' ')
}

export class ValidateNumberInput {
  constructor(input, defaultValue = 1, method = 'input') {
    this._input = input

    this._input.addEventListener(method, (event) => {
      if (this._input.value == '') return
      this._input.value = validateNumber(event.target.value, defaultValue)
    })
    this._input.addEventListener('change', (event) => {
      if (this._minVal != null && this._input.value < this._minVal)
        this._input.value = this._minVal
      else if (this._maxVal != null && this._input.value > this._maxVal)
        this._input.value = this._maxVal
    })
    return this
  }

  min(minVal) {
    this._minVal = minVal
    return this
  }

  max(maxVal) {
    this._maxVal = maxVal
    return this
  }
}

export function cyr2lat(str) {

  var cyr2latChars = new Array(
    ['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'],
    ['д', 'd'],  ['е', 'e'], ['ё', 'yo'], ['ж', 'zh'], ['з', 'z'],
    ['и', 'i'], ['й', 'y'], ['к', 'k'], ['л', 'l'],
    ['м', 'm'],  ['н', 'n'], ['о', 'o'], ['п', 'p'],  ['р', 'r'],
    ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'],
    ['х', 'h'],  ['ц', 'c'], ['ч', 'ch'],['ш', 'sh'], ['щ', 'shch'],
    ['ъ', ''],  ['ы', 'y'], ['ь', ''],  ['э', 'e'], ['ю', 'yu'], ['я', 'ya'],

    ['А', 'A'], ['Б', 'B'],  ['В', 'V'], ['Г', 'G'],
    ['Д', 'D'], ['Е', 'E'], ['Ё', 'YO'],  ['Ж', 'ZH'], ['З', 'Z'],
    ['И', 'I'], ['Й', 'Y'],  ['К', 'K'], ['Л', 'L'],
    ['М', 'M'], ['Н', 'N'], ['О', 'O'],  ['П', 'P'],  ['Р', 'R'],
    ['С', 'S'], ['Т', 'T'],  ['У', 'U'], ['Ф', 'F'],
    ['Х', 'H'], ['Ц', 'C'], ['Ч', 'CH'], ['Ш', 'SH'], ['Щ', 'SHCH'],
    ['Ъ', ''],  ['Ы', 'Y'],
    ['Ь', ''],
    ['Э', 'E'],
    ['Ю', 'YU'],
    ['Я', 'YA'],

    ['a', 'a'], ['b', 'b'], ['c', 'c'], ['d', 'd'], ['e', 'e'],
    ['f', 'f'], ['g', 'g'], ['h', 'h'], ['i', 'i'], ['j', 'j'],
    ['k', 'k'], ['l', 'l'], ['m', 'm'], ['n', 'n'], ['o', 'o'],
    ['p', 'p'], ['q', 'q'], ['r', 'r'], ['s', 's'], ['t', 't'],
    ['u', 'u'], ['v', 'v'], ['w', 'w'], ['x', 'x'], ['y', 'y'],
    ['z', 'z'],

    ['A', 'A'], ['B', 'B'], ['C', 'C'], ['D', 'D'],['E', 'E'],
    ['F', 'F'],['G', 'G'],['H', 'H'],['I', 'I'],['J', 'J'],['K', 'K'],
    ['L', 'L'], ['M', 'M'], ['N', 'N'], ['O', 'O'],['P', 'P'],
    ['Q', 'Q'],['R', 'R'],['S', 'S'],['T', 'T'],['U', 'U'],['V', 'V'],
    ['W', 'W'], ['X', 'X'], ['Y', 'Y'], ['Z', 'Z'],

    [' ', '-'],['0', '0'],['1', '1'],['2', '2'],['3', '3'],
    ['4', '4'],['5', '5'],['6', '6'],['7', '7'],['8', '8'],['9', '9'],
    ['-', '-']

  );

  var newStr = new String();

  for (var i = 0; i < str.length; i++) {

    var ch = str.charAt(i);
    var newCh = '';

    for (var j = 0; j < cyr2latChars.length; j++) {
      if (ch == cyr2latChars[j][0]) {
        newCh = cyr2latChars[j][1];

      }
    }
    newStr += newCh;

  }
  return newStr.replace(/[-]{2,}/gim, '-').replace(/\n/gim, '').toLowerCase();
}

export function gotoBlock(
    targetBlock,
    noHeader = false,
    speed = 500,
    offsetTop = 0
) {
  const targetBlockElement = typeof targetBlock === 'string' ? document.querySelector(targetBlock) : targetBlock;
  if (targetBlockElement) {
    let headerItem = "";
    let headerItemHeight = 0;
    if (noHeader) {
      headerItem = ".page-header__fixed";
      headerItemHeight = document.querySelector(headerItem).offsetHeight;
    }

    let targetBlockElementPosition =
        targetBlockElement.getBoundingClientRect().top + scrollY;
    targetBlockElementPosition = headerItemHeight
        ? targetBlockElementPosition - headerItemHeight
        : targetBlockElementPosition;
    targetBlockElementPosition = offsetTop
        ? targetBlockElementPosition - offsetTop
        : targetBlockElementPosition;
    window.scrollTo({
      top: targetBlockElementPosition,
      behavior: "smooth",
    });
  }
};


export default utils;
