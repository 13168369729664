import * as Swiper from 'swiper/dist/js/swiper.js';

document.addEventListener('DOMContentLoaded', () => {
    [...document.querySelectorAll('.advantages')].forEach(why_alfaforex_slider => {

        const slider = why_alfaforex_slider.querySelector('.advantages__slider');
        const bullets = why_alfaforex_slider.querySelector('.advantages__pagination');

        if (!slider) {
            return;
        }

        const advantagesSlider = new Swiper(slider, {
            init: true,
            slidesPerView: 'auto',
            speed: 500,
            spaceBetween: 10,
            autoHeight: false,
            loop: false,
            pagination: {
                el: bullets,
                type: 'bullets',
                clickable: true,
            },
        });

    });
});
