import * as Swiper from 'swiper/dist/js/swiper.js';

document.addEventListener('DOMContentLoaded', () => {
    [...document.querySelectorAll('.why-alfaforex__benefits-mobile')].forEach(why_alfaforex_slider => {

        const slider = why_alfaforex_slider.querySelector('.why-alfaforex__benefits-slider');
        const bullets = why_alfaforex_slider.querySelector('.why-alfaforex__benefits-pagination');

        if (!slider) {
            return;
        }

        if (window.innerWidth < 991) {
            const whyAFSlider = new Swiper(slider, {
                init: true,
                slidesPerView: 1,
                speed: 500,
                spaceBetween: 10,
                autoHeight: false,
                loop: false,
                pagination: {
                    el: bullets,
                    type: 'bullets',
                    clickable: true,
                },
            });
        }

    });
});
